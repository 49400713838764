<template>
    <section class="pt-lg-8 pt-4 place-holder-white">

        <div class="container full-height pt-lg-4 pt-8">

            <login-heading heading="Set a New Password" size="lg">
                <template #info>
                    <p class="d-inline mr-1">Please enter your new password</p>
                </template>
            </login-heading>

            <div class="mt-3 fl-x" style="color: white !important;" v-if="showSuccess">
                <span><i class="fa fa-check-circle fa-3x text-success"></i></span>
                <span class="ml-lg-5 fs-lg-2">
                        Your new password has been updated. <br>
                        Please <router-link class="text-decoration-none text-secondary"
                                            to="/login/">Login</router-link>
                        to continue.
                    </span>
            </div>

            <b-form v-else :key="formKey" class="row" ref="contactForm" :save-url="addUrl" @success="formSuccess"
                    v-slot="{model, loading}" :save-params="{email: $route.params.email}">

                <div class="col-12 col-lg-4 col-md-6 mt-lg-5">

                    <validated-input :type="inputType" label="Enter new password" :disabled="loading"
                                     class="c-input" v-model="model.new_password" :rules="{required: true}"/>
                    <p class="mt-3 mb-3 text-3 fs--1">Password must contain at least 8 characters, including upper &
                        lower
                        case letters, numbers and
                        special characters.</p>
                    <validated-input :type="inputType" label="Confirm new password" :disabled="loading"
                                     class="c-input" v-model="model.conform_password" :rules="{required: true}"/>

                    <validated-checkbox label="Show Password" class="c-input-checkbox"
                                        :disabled="loading" v-model="showPasswordType" name="Email"/>

                    <btn block text="Submit" class="mt-4 border-r--2 auth-button py-3"></btn>

                </div>
            </b-form>

        </div>

    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';
import urls from '@/data/urls';

export default {
    name: 'SetNewPassword',

    components: { LoginHeading },

    watch: {
        showPasswordType: {
            handler () {
                this.showPassword();
            }
        }
    },

    data () {
        return {
            inputType: 'password',
            showPasswordType: false,
            showSuccess: false,

            formKey: 1,

            addUrl: urls.auth.resetPassword
        };
    },

    methods: {
        showPassword () {
            if (this.showPasswordType === false) {
                this.inputType = 'password';
            } else {
                this.inputType = 'text';
            }
        },

        formSuccess () {
            this.showSuccess = true;
            this.formKey += 1;
        }
    }
};
</script>

<style scoped>

</style>
